<nav class="navbar fixed-top navbar-expand-sm navbar-dark p-0">
  <div class="container-fluid bg-landing d-flex py-2 px-4">
    <div class="navbar-brand">
      <a href=""><img class="logo" src="assets/logo.svg" alt="Danfoss" /></a>
      <img src="assets/engineering-tomorrow.png" alt="engineering tomorrow" />
    </div>

    <input type="checkbox" id="menuToggle" class="navbar-toggler-checkbox" />
    <label for="menuToggle" class="navbar-toggler"><span class="navbar-toggler-icon"></span></label>

    <div class="navbar-collapse pt-2 pt-sm-0">
      <div class="navbar-nav me-auto">
        <a
          class="nav-item nav-link"
          href="https://www.danfoss.com/en/products/dps/software/software-and-tools/plus1-software/plus1-mobile-service-tool/#tab-overview"
        >
          Learn More
        </a>
      </div>

      <div class="navbar-nav login">
        <a href="app" class="btn btn-outline-white d-none d-sm-inline-block">Log in</a>
        <a href="app" class="nav-item nav-link d-inline-block d-sm-none">Log in</a>
      </div>
    </div>
  </div>
</nav>
<div class="mt-0 p-5 bg-primary text-white rounded screen-1">
  <h1 class="display-4">PLUS+1® Service Tool</h1>
  <p class="lead">Maintain and diagnose your machine equipment in the field.</p>
  <a href="app/onboarding/welcome" class="btn btn-outline-white btn-lg">Sign up</a>
</div>

<div class="screen-2">
  <h2>
    A comprehensive tool to assemble packages and design app views that support your maintenance of machines. Prepare at
    home, install on the go.
  </h2>

  <div class="steps">
    <div class="container-fluid">
      <div class="row d-none d-sm-block">
        <div class="col-12 icons-line"></div>
      </div>
      <div class="row">
        <div class="col-sm-3">
          <p class="icon-container">
            <fa-icon [icon]="icon.laptop" [fixedWidth]="true"></fa-icon>
          </p>
          <h5>1. Assemble package</h5>
          <p>Collect all your configurations and firmware into packages in the web application.</p>
        </div>
        <div class="col-sm-3">
          <p class="icon-container">
            <fa-icon [icon]="icon.edit" [fixedWidth]="true"></fa-icon>
          </p>
          <h5>2. Compose app views</h5>
          <p>Compose app views with tools you need to diagnose machines in the field.</p>
        </div>
        <div class="col-sm-3">
          <p class="icon-container">
            <fa-icon [icon]="icon.mobile" [fixedWidth]="true"></fa-icon>
          </p>
          <h5>3. Sync with mobile app</h5>
          <p>Download the mobile application and sync packages to your smart phone.</p>
        </div>
        <div class="col-sm-3">
          <p class="icon-container">
            <fa-icon [icon]="icon.truck" [fixedWidth]="true"></fa-icon>
          </p>
          <h5>4. Operate on the field</h5>
          <p>Use the application to install or maintain already installed firmware to machines on the field.</p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="screen-3">
  <h2>Feature comparison</h2>

  <div class="row row-cols-1 row-cols-sm-3 cards">
    <div class="col mb-3">
      <div class="card h-100">
        <div class="card-header">
          <h3 class="m-0 text-center">Technician</h3>
        </div>
        <div class="card-body">
          <h4 class="card-title">Free</h4>
          <div class="card-text">
            <ul>
              <li>Use iOS and Android mobile applications</li>
              <li>Connect to machines over Wi-Fi or Bluetooth</li>
              <li>Access service packages created by others</li>
              <li>Read and write machine parameters</li>
              <li>Update machine firmware</li>
            </ul>
          </div>
          <a href="app/onboarding/welcome" class="btn d-block w-100 btn-landing">Sign up for free</a>
        </div>
      </div>
    </div>
    <div class="col mb-3">
      <div class="card h-100">
        <div class="card-header">
          <h3 class="m-0 text-center">Developer</h3>
        </div>
        <div class="card-body">
          <h4 class="card-title">Requires Professional license</h4>
          <div class="card-text">
            <ul>
              <li><strong>Everything available to technician</strong></li>
              <li>Manage your machines information</li>
              <li>Design service packages for personal use</li>
              <li>Use editor to compose app views</li>
              <li>Store service packages in the cloud</li>
            </ul>
          </div>
          <a href="app/onboarding/license/developer" class="btn d-block w-100 btn-landing">Get started</a>
        </div>
      </div>
    </div>
    <div class="col mb-3">
      <div class="card h-100">
        <div class="card-header">
          <h3 class="m-0 text-center">Enterprise</h3>
        </div>
        <div class="card-body">
          <h4 class="card-title">Requires Cloud Add-on</h4>
          <div class="card-text">
            <ul>
              <li><strong>Everything available to developer</strong></li>
              <li>Get a dedicated workspace</li>
              <li>Invite other users to the workspace</li>
              <li>Collaborate on service packages</li>
              <li>Distribute service packages</li>
            </ul>
          </div>
          <a href="app/onboarding/license/owner" class="btn d-block w-100 btn-landing">Get started</a>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="screen-4">
  <div class="cards">
    <div class="row row-cols-1 row-cols-sm-2">
      <div class="col mb-3">
        <div class="card h-100">
          <img class="card-img-top" src="assets/web-card.jpg" alt="Web application" />
          <div class="card-body">
            <h3 class="card-title">Request a PLUS+1® Professional license</h3>
            <div class="card-text">
              <p>
                It is required to have a PLUS+1® Professional license to design service packages. Please contact
                Danfoss sales to purchase one.
              </p>
            </div>
            <a
              href="https://www2.powersolutions.danfoss.com/l/38972/2016-05-23/51gfb1"
              class="btn d-block w-100 btn-landing mt-auto"
            >
              Contact
            </a>
          </div>
        </div>
      </div>

      <div class="col mb-3">
        <div class="card h-100">
          <img class="card-img-top" src="assets/mobile-card.jpg" alt="Web application" />
          <div class="card-body">
            <h3 class="card-title">Download the app</h3>
            <div class="card-text">
              <p>
                Use the mobile app to connect to machines in the field. Easily update firmware, download log files and
                diagnose problems.
              </p>

              <p>Download for free on the Google Play Store or App Store.</p>
            </div>

            <div class="mt-auto stores">
              <a href="https://play.google.com/store/apps/details?id=com.danfoss.plus1.servicetool">
                <img src="assets/google-play-badge.svg" class="me-2" alt="Google Play" />
              </a>
              <a href="https://itunes.apple.com/us/app/plus-1-servicetool/id1237608220">
                <img src="assets/app-store-badge.svg" alt="App Store" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<footer>
  <div class="container-fluid">
    <div class="row">
      <div class="col-sm-auto">
        <a href="https://www.danfoss.com/en/terms/privacy/" class="secondary__list-item-link non-decorated">
          Privacy policy
        </a>
      </div>
      <div class="col-sm-auto">
        <a href="https://www.danfoss.com/en/terms/" class="secondary__list-item-link non-decorated"> Terms of use </a>
      </div>
      <div class="col-sm-auto">
        <a href="https://www.danfoss.com/en/terms/generalinformation/" class="secondary__list-item-link non-decorated">
          General information
        </a>
      </div>
      <div class="col-sm-auto">
        <a href="javascript:CookieConsent.renew()" class="secondary__list-item-link non-decorated"> Cookies </a>
      </div>
    </div>
  </div>
</footer>
